var maps = [
	[
		"                                      ",
		"                                      ",
		"                                      ",
		"                                      ",
		"                                      ",
		"     &   =*=%=                        ",
		"                                      ",
		"                            -+        ",
		"                    ^   ^   ()        ",
		"==============================   =====",
	],
	[
		"£                                       £",
		"£                                       £",
		"£                                       £",
		"£                                       £",
		"£                                       £",
		"£        @@@@@@              x x        £",
		"£                          x x x        £",
		"£                        x x x x  x   -+£",
		"£               z   z  x x x x x  x   ()£",
		"!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
	],
];

export { maps };